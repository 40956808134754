import { useContext, useEffect } from 'react';
import { dataContext } from '../context/DataProvider';
import { useTable } from 'react-table';
import { Appointments } from '../services/Appointments';
import { getAppointmentService } from '../services/ServiceFactory';
import { STATUS } from '../constants/DataProvider';

const appointmentService: Appointments = getAppointmentService();

export const AppointmentsTable = () => {
  const [state, dispatch] = useContext(dataContext);

  useEffect(() => {
    console.log('Inside effect');
    if ((state.userAppointments.length === 0) && (state.status === STATUS.OK)) {
      appointmentService
        .getAll()
        .then((result) => {
          console.log(result);
          dispatch({
            type: 'APPOINTMENTS_DATA',
            payload: result
          });
        });
    }
  });
  return <Table columns={columns} data={state.userAppointments} />;
};

function Table ({ columns, data }: any) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  });

  // Render the UI for your table
  return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any, index: number) => (
            <tr key="tr1_{index}" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th key="th_{index}"{...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, index: number) => {
            prepareRow(row);
            return (
              <tr key="tr2_{index}"{...row.getRowProps()}>
                {row.cells.map((cell: any, index: number) => {
                  return <td key="tr2_{index}" {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
  );
}

const columns = [
  {
    Header: 'Operation',
    accessor: 'operation'
    // // eslint-disable-next-line react/display-name
    // cell: () => <input type="button" value="+" onClick = {buttonToggleEventHandler}></input>,
    // button: true,
    // ignoreRowClick: true
  },
  {
    Header: 'Appointment',
    accessor: 'appointment'
  },
  {
    Header: 'Info',
    accessor: 'info'
  }
];
