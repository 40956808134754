import { Configuration } from './Config';

export const LOCAL_ENV:string = 'local';

export class Config implements Configuration {
  env: string;
  constructor () {
    if (process.env.REACT_APP_NODE_ENV) {
      this.env = process.env.REACT_APP_NODE_ENV;
    } else {
      this.env = 'prod';
    }
  }

  isDebug (): boolean {
    if (this.env === 'prod') {
      return false;
    } else {
      return true;
    }
  }

  getEnv (): string {
    return this.env;
  }
}
