import { API } from 'aws-amplify';
import { RestService } from './Rest';

export class RestServiceImpl implements RestService {
  get (apiName: string, path: string, params: any): Promise<any> {
    return API.get(apiName, path, params);
  }

  put (apiName: string, path: string, params: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  post (apiName: string, path: string, params: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  delete (apiName: string, path: string, params: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
}

export class DevRestServiceImpl implements RestService {
  get (apiName: string, path: string, params: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      resolve([
        {
          business: 'Mock Business 1',
          positionInQueue: 1,
          appointmentTime: '9:30pm',
          expectedTimeForAppointment: '9:35',
          businessTimings: '9:00am to 9:00pm',
          comments: 'Please be 15 mins before your appointment'
        },
        {
          business: 'business Mock Business 2',
          positionInQueue: 10,
          appointmentTime: '10:30pm',
          expectedTimeForAppointment: '9:35',
          businessTimings: '9:00am to 9:00pm',
          comments: 'Please be 15 mins before your appointment'
        }
      ]);
    });
  }

  put (apiName: string, path: string, params: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  post (apiName: string, path: string, params: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  delete (apiName: string, path: string, params: any): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
