import { createContext, useReducer } from 'react';
import reducer from './DataProviderReducer';
import { STATUS } from '../constants/DataProvider';
import { SkippyQData } from '../types/DataProvider';

const initialState: SkippyQData = { userAppointments: [], status: STATUS.OK };
export const dataContext = createContext<Array<any>>([]);

export const DataProviderContext = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <dataContext.Provider value={[state, dispatch]}>
      {props.children}
    </dataContext.Provider>
  );
};
