import { RestService } from './Rest';
import { DevRestServiceImpl, RestServiceImpl } from './RestImpl';

import { Appointments } from './Appointments';
import { AppointmentsImpl } from './AppointmentsImpl';

import { Configuration } from './Config';
import { Config } from './ConfigImpl';

import { UserInfo } from './UserInfo';
import { DevUserInfoImpl, UserInfoImpl } from './UserInfoImpl';

const devRestServiceImpl = new DevRestServiceImpl();
const restService = new RestServiceImpl();

export const getConfigurtaionService = (): Configuration => (new Config());

export const getRestService = (): RestService => {
  if (getConfigurtaionService().getEnv() === 'prod') {
    return restService;
  }
  return devRestServiceImpl;
};

export const getAppointmentService = (): Appointments => (new AppointmentsImpl());

export const getUserInfoService = (): UserInfo => {
  if (getConfigurtaionService().getEnv() === 'prod') {
    return new UserInfoImpl();
  }
  return new DevUserInfoImpl();
};
