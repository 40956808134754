import { SkippyQData } from '../types/DataProvider';
import { STATUS } from '../constants/DataProvider';

// reducer.js
export default function reducer (state: SkippyQData, action: any): any {
  let data: any = [];
  switch (action.type) {
    case 'get':
      return {
        userAppointments: [],
        status: STATUS.OK
      };
    case 'APPOINTMENTS_DATA':
      data = action.payload.map((inputData: any) =>
        ({
          operation: 'create',
          appointment: `Appointment Time: ${inputData.appointmentTime} Position: ${inputData.positionInQueue} Expected time of appointment: ${inputData.expectedTimeForAppointment}`,
          info: inputData.comments
        })
      );
      data.push({ operation: 'Create', appointment: '', info: 'Click on create to add an appointment' });
      return {
        userAppointments: data,
        status: STATUS.OK
      };
    default:
      throw new Error();
  }
}
