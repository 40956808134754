import { UserInfo } from './UserInfo';
import { Auth } from 'aws-amplify';

export class UserInfoImpl implements UserInfo {
  async getUserLogin (): Promise<string> {
    const currentUserInfo = await Auth.currentUserInfo();
    return currentUserInfo.attributes.email;
  }
}

export class DevUserInfoImpl implements UserInfo {
  getUserLogin (): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      resolve('bittuhs@gmail.com');
    });
  }
}
