import { Appointments } from './Appointments';
import { RestService } from './Rest';
import { getRestService, getUserInfoService } from './ServiceFactory';

const API_NAME = 'appointments';
const API_BASE_PATH = '/appointments';

export class AppointmentsImpl implements Appointments {
  client: RestService;
  userLogin?: string;
  public constructor () {
    this.client = getRestService();
  }

  getDetails (id: string): Promise<any> {
    return this.client.get(API_NAME, `${API_BASE_PATH}/${id}`, {});
  }

  async getAll (): Promise<any> {
    if (!this.userLogin) {
      this.userLogin = await getUserInfoService().getUserLogin();
    }
    console.log(`User Login = ${this.userLogin}`);
    return this.client.get(API_NAME, API_BASE_PATH, {
      queryStringParameters: {
        login: this.userLogin
      }
    });
  }

  create (details: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  delete (id: string): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
