
export type Status = {
  OK: string,
  ERROR: string,
  LOADING: string,
}

export const STATUS: Status = {
  OK: 'OK',
  ERROR: 'Error',
  LOADING: 'Loading'
};
