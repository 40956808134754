
export const APPOINTMENTS: string = 'Appointments ';
export const ABOUT: string = 'About ';
export const SUPPORT: string = 'Support ';
export const LOGIN: string = 'Login ';
export const LOGOUT: string = 'Logout';

export const MENU_LIST: Array<string> = [
  APPOINTMENTS, ABOUT, SUPPORT, LOGIN, LOGOUT
];
