// import logo from './logo.svg';
import './App.css';
import { TabMenu } from './pages/LandingPage';
import { MENU_LIST } from './constants/Landingpage';
import { withAuthenticator } from '@aws-amplify/ui-react';

import { Configuration } from './services/Config';
import { Config, LOCAL_ENV } from './services/ConfigImpl';

export const CONFIG_KEY = 'config';
const configuration: Configuration = new Config();

const data: Map<string, any> = new Map();
data.set(CONFIG_KEY, configuration);

function App () {
  return (
    <div className="App">
      <TabMenu tabs={MENU_LIST}></TabMenu>
    </div>
  );
}

export default (configuration.getEnv() === LOCAL_ENV ? App : withAuthenticator(App));
