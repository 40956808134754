import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { APPOINTMENTS, ABOUT, SUPPORT, LOGIN, LOGOUT } from '../constants/Landingpage';
// import { AppointmentDataProvider } from '../components/AppointmentDataProvider';
import { AppointmentsTable } from '../components/AppointmentsTable';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { useContext } from 'react';
import { dataContext } from '../context/DataProvider';

type MenuProps = {
    tabs: Array<string>
}

const MENU_CONTENT_MAP: Map<string, Function> = new Map([
  [APPOINTMENTS, () => { return (<AppointmentsTable/>); }],
  [ABOUT, () => { return (<h2>About </h2>); }],
  [SUPPORT, () => { return (<h2>Support </h2>); }],
  [LOGIN, () => { return (<h2>Login </h2>); }],
  [LOGOUT, () => { return (<AmplifySignOut/>); }]
]);

export const TabMenu = (menuProps: MenuProps) => {
  const value = useContext(dataContext);

  return (
    <div>
      <Tabs>
          <TabList>
              {menuProps.tabs.map(function (name, index) {
                return <Tab key={index}>{name}</Tab>;
              })}
          </TabList>

          {menuProps.tabs.map(function (name: string, index: number) {
            return (
              <TabPanel key={index}>
                {MENU_CONTENT_MAP.get(name)!()}
              </TabPanel>
            );
          })}
      </Tabs>
      <p>Status = {value[0].status}</p>
    </div>
  );
};
