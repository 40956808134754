/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8ea4cb61-7cfc-48b3-a79b-bd28a296b7ca",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dfKQHPCW0",
    "aws_user_pools_web_client_id": "2agt1v5vj5e0me2cpgu574qcbg",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "appointments",
            "endpoint": "https://3p18cz9b55.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://w3fynxmhxnbcnkzrmozrltbwdi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6o6g6yy6mjcbljy26cuidgz52u"
};


export default awsmobile;
